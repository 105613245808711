import React, { useState, useEffect } from "react";
import Header from "./images/Header.svg";
import NIETLogo from "./images/NIETLogo.png";
import LDOELogo from "./images/LDOE-Logo.png";
import NIETSignature from "./images/NIETSignature.png";
import "./PrintCertification.scss";
import moment from "moment";
import PrintLayout from "../../../../components/layout/PrintLayout";
import { FormatUTCDateM } from "../../../../utilities/DateFormater";


function PrintCertification({ close, data, observationConfig, certType }) {
  const [loaded, setLoaded] = useState({ allLoaded: false, NIETLogoFile: false, CertificationLogoFile: false });
  const [loadedDOELogo, setLoadedDOELogo] = useState(false);

  useEffect(() => {
    if (!loaded.allLoaded && observationConfig && data && loaded.NIETLogoFile) {
      const isAdminCertType = certType === 1;
      const isTeacherCertType = certType === 2;
  
      const adminCertFileLoaded = observationConfig.CertificationLogAdminFileId && loaded.CertificationLogoFile;
      const adminCertFileNotRequired = !observationConfig.CertificationLogAdminFileId && !loaded.CertificationLogoFile;
  
      const teacherCertFileLoaded = observationConfig.CertificationLogTeacherFileId && loaded.CertificationLogoFile;
      const teacherCertFileNotRequired = !observationConfig.CertificationLogTeacherFileId && !loaded.CertificationLogoFile;
  
      const shouldLoadAdminCert = isAdminCertType && (adminCertFileLoaded || adminCertFileNotRequired);
      const shouldLoadTeacherCert = isTeacherCertType && (teacherCertFileLoaded || teacherCertFileNotRequired);
  
      if (shouldLoadAdminCert || shouldLoadTeacherCert) {
        setLoaded({ allLoaded: true });
      }
    }
  }, [observationConfig, data, loaded, certType]);

  const preloadImage = (src, field) => {
    const img = new Image();
    img.onload = () => handleLoad(field);
    img.src = src;
  };

  useEffect(() => {
    setLoaded({ allLoaded: false, NIETLogoFile: false, CertificationLogoFile: false });

    if (certType === 1 && observationConfig?.CertificationLogAdminFile?.FileUrl) {
      preloadImage(observationConfig.CertificationLogAdminFile.FileUrl, "CertificationLogoFile");
    } else if (certType === 2 && observationConfig?.CertificationLogTeacherFile?.FileUrl) {
      preloadImage(observationConfig.CertificationLogTeacherFile.FileUrl, "CertificationLogoFile");
    }

    preloadImage(NIETLogo, "NIETLogoFile");
  }, [data, observationConfig, certType]);

  const handleClose = () => {
    setLoaded(false);
    setLoadedDOELogo(false);
    close();
  }

  const handleLoad = (field) => {
    setLoaded({ ...loaded, [field]: true });
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
  
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
  
    // Function to add the correct ordinal suffix
    const addOrdinalSuffix = (day) => {
      if (day > 3 && day < 21) return `${day}th`;
      switch (day % 10) {
        case 1: return `${day}st`;
        case 2: return `${day}nd`;
        case 3: return `${day}rd`;
        default: return `${day}th`;
      }
    };
  
    const day = date.getDate();
    const dayWithSuffix = addOrdinalSuffix(day);
  
    // Replace the day in the formatted date with the day including the ordinal suffix
    return formattedDate.replace(day, dayWithSuffix);
  }

  return (
    <>
      {observationConfig?.Client?.DisplayName === 'Louisiana LAER' ? (
        <PrintLayout
        print={loadedDOELogo}
        cleanUp={handleClose}
        className={'print-wrapper-layout'}
        >
          <div className="print-modal-wrapper">
            <div className="print-modal lear-modal">
              <div className="print-modal-bottom">
                <div className="print-modal-bottom-logos-lear">
                  <img
                    src={LDOELogo}
                    alt="Louisianna Department of Education Logo"
                    onLoad={() => setLoadedDOELogo(true)}
                  />
                </div>
              </div>
              <div className="print-modal-header">
                <h2 className="niet-blue">Certificate of Completion</h2>
                <div className="print-modal-header-descriptor lear-header-descriptor">
                  <h3 className="niet-blue niet-bold">IS PRESENTED TO</h3>
                </div>
                <h2 className="lear-body-descriptor">{data?.User?.FirstName.toUpperCase()}{' '}{data?.User?.LastName.toUpperCase()}</h2>
              </div>
              <div className="print-modal-body">
                <div className="print-modal-body-descriptor niet-bold">
                  <h3 className="niet-bold niet-blue">
                    for completion of the course{" "}
                    <span className="lear-body-descriptor niet-bold">
                      Louisiana Aspiring Educator Evaluation Certification Assessment
                    </span>
                    {" "}
                    on {" "}
                    {formatDate(data?.CompletedDate)}. 
                  </h3>
                  <br/>
                  <h3 className="niet-bold niet-blue">
                    This course consists of <span className="lear-body-descriptor">2 hours</span> of training.
                  </h3>
                  <br></br>
                  <h5 className="niet-blue laer-description">
                    This certificate documents that the awardee is approved to conduct aspiring educator evaluation under the Louisiana Educator
                    <br/>
                    <span className="niet-blue">
                      Advancement & Development System for the {data?.SchoolYearIdentifier - 1}-{data?.SchoolYearIdentifier} school year.
                    </span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </PrintLayout>
      ) : (
        //Show Default Template
        <PrintLayout
        print={loaded?.allLoaded}
        cleanUp={handleClose}
        className={'print-wrapper-layout'}
        >
          <div className="print-modal-wrapper">
            <div className="print-modal">
              <div className="print-modal-header">
                <img src={Header} alt="Header" />
                <div className="print-modal-header-descriptor">
                  <h3>This certifies that</h3>
                </div>
                <h2>{data?.User?.FirstName}{' '}{data?.User?.LastName}</h2>
              </div>
              <div className="print-modal-body">
                <div className="print-modal-body-descriptor">
                  <h3>
                    has demonstrated proficiency in the{" "}
                    {certType == 1 ? observationConfig?.CertificationNameAdmin : observationConfig?.CertificationNameTeacher}
                    {" "}
                    and is a
                  </h3>
                </div>
                <div className="print-modal-body-year">
                  <h2>{data?.SchoolYearIdentifier - 1}-{data?.SchoolYearIdentifier}</h2>
                </div>
                <div className="print-modal-body-title">
                  <h2>
                    {(observationConfig?.OrganizationId?.toUpperCase() == '3EC1CB94-BB53-4D34-BA4D-C2BA4CE6F4AA') ? 'Certified ATR Evaluator' : 
                      certType == 1 ? observationConfig?.CertificationTitleAdmin : observationConfig?.CertificationTitleTeacher}
                  </h2>
                </div>
              </div>
              <div className="print-modal-bottom">
                <div className="print-modal-bottom-descriptor">
                  <h5>
                    An initiative of the National Institute for Excellence in
                    Teaching
                  </h5>
                </div>
                <div className="print-modal-bottom-logos">
                  <img
                    src={NIETLogo}
                    alt="Niet Logo"
                    onLoad={() => handleLoad("NIETLogoFile")}
                  />
                  <div className="print-modal-bottom-logos-box">
                    {certType == 1 && observationConfig?.CertificationLogAdminFileId ? (
                      <img
                        src={observationConfig?.CertificationLogAdminFile?.FileUrl}
                        alt="Certification Logo"
                        onLoad={() => handleLoad("CertificationLogoFile")}
                      />
                    )
                      : certType == 2 && observationConfig?.CertificationLogTeacherFileId && (
                        <img
                          src={observationConfig?.CertificationLogTeacherFile?.FileUrl}
                          alt="Certification Logo"
                          onLoad={() => handleLoad("CertificationLogoFile")}
                        />
                      )
                    }
                  </div>
                </div>
              </div>
              <div className="print-modal-footer-wrapper">
                <div className="print-modal-footer">
                  <div className="print-modal-footer-signature">
                    <img src={NIETSignature} alt="Signature" />
                    <span className="print-modal-footer-signline" />
                    <h5>Dr. Joshua Barnett, Chief Executive Officer</h5>
                  </div>
                  <div className="print-modal-footer-date">
                    <h4>
                      {FormatUTCDateM(data?.CompletedDate, "MM/DD/YYYY")}
                    </h4>
                    <span className="print-modal-footer-signline" />
                    <h5>Date</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PrintLayout>
      )}
    </>
  );
};

export default PrintCertification;
