export const getReportByUrl = (reportUrl) => {
  switch (reportUrl) {
    case "school-administrator-evaluator-certification":
      return {
        getPermissions: () => [
          "ReportsSchoolAdministratorEvaluatorCertificationStatus",
          "ReportsSchoolAdministratorEvaluatorCertificationResets",
          "ReportsSchoolAdministratorEvaluatorCertificationFailures",
          "ReportsSchoolAdministratorEvaluatorCertificationResults"
        ],
        reportTitle: "School Administrator Evaluator Certification",
        getReportType: (reportTypeIndex) => {
          switch (reportTypeIndex) {
            case "0":
              return "status";
            case "1":
              return "resets";
            case "2":
              return "failures";
            case "3":
              return "results";
            default:
              return "";
          }
        },
        getReportTrdp: (reportType) => {
          switch (reportType) {
            case "status":
              return "SchoolAdministratorEvaluatorCertificationStatus.trdp";
            case "resets":
              return "SchoolAdministratorEvaluatorCertificationResets.trdp";
            case "failures":
              return "SchoolAdministratorEvaluatorCertificationFailures.trdp";
            case "results":
              return "SchoolAdministratorEvaluatorCertificationResults.trdp";
            default:
              return "";
          }
        },
        getReportParameters: (reportParameters, reportType) => {
          switch (reportType) {
            case "status":
              return {
                DistrictId: reportParameters.districtId,
                Inactive: reportParameters.includeInactiveUsers,
                isd: reportParameters.includeDirectors,
                isn: reportParameters.includeNietAdministrators,
                OrganizationId: reportParameters.organizationId,
                SchoolId: reportParameters.schoolId,
                SchoolYear: reportParameters.schoolYear,
              };
            case "resets":
            case "failures":
            case "results":
              return {
                DistrictId: reportParameters.districtId,
                OrganizationId: reportParameters.organizationId,
                SchoolId: reportParameters.schoolId,
                SchoolYear: reportParameters.schoolYear,
              };
            default:
              return {};
          }
        },
        getReportParametersDisplay: (reportParametersDisplay, reportType) => {
          reportParametersDisplay.isEvaluatorCertificationTypeDisplayed = true;
          reportParametersDisplay.isOrganizationDisplayed = true;
          reportParametersDisplay.isDistrictDisplayed = true;
          reportParametersDisplay.isSchoolDisplayed = true;
          reportParametersDisplay.isSchoolYearDisplayed = true;

          if (reportType === "status") {
            reportParametersDisplay.isIncludeInactiveUsersDisplayed = true;
            reportParametersDisplay.isIncludeNietAdministratorsDisplayed = true;
            reportParametersDisplay.isIncludeDirectorsDisplayed = true;
          }
          return reportParametersDisplay;
        },
        getRequiredReportParameters: (requiredReportParameters, reportType) => {
          requiredReportParameters.isReportTypeRequired = true;
          requiredReportParameters.isOrganizationIdRequired = true;
          //requiredReportParameters.isSchoolYearRequired = true;
          if (reportType === "status") {
            requiredReportParameters.isIncludeInactiveUsersRequired = true;
          }
          return requiredReportParameters;
        },
      };
    case "school-administrator-evaluator-certification-status":
      return {
        getPermissions: () => ["ReportsSchoolAdministratorEvaluatorCertificationStatus"],
        reportTitle: "School Admin Evaluator Certification Status",
        getReportTrdp: () => {
          return "SchoolAdministratorEvaluatorCertificationStatus.trdp";
        },
        getReportParameters: (reportParameters,) => {
          return {
            DistrictId: reportParameters.districtId,
            Inactive: reportParameters.includeInactiveUsers,
            isd: reportParameters.includeDirectors,
            isn: reportParameters.includeNietAdministrators,
            OrganizationId: reportParameters.organizationId,
            SchoolId: reportParameters.schoolId,
            SchoolYear: reportParameters.schoolYear,
          };
        },
        getReportParametersDisplay: (reportParametersDisplay, reportType) => {
          reportParametersDisplay.isEvaluatorCertificationTypeDisplayed = false;
          reportParametersDisplay.isOrganizationDisplayed = true;
          reportParametersDisplay.isDistrictDisplayed = true;
          reportParametersDisplay.isSchoolDisplayed = true;
          reportParametersDisplay.isSchoolYearDisplayed = true;
          reportParametersDisplay.isIncludeInactiveUsersDisplayed = true;
          reportParametersDisplay.isIncludeNietAdministratorsDisplayed = true;
          reportParametersDisplay.isIncludeDirectorsDisplayed = true;
          return reportParametersDisplay;
        },
        getRequiredReportParameters: (requiredReportParameters) => {
          requiredReportParameters.isReportTypeRequired = true;
          requiredReportParameters.isOrganizationIdRequired = true;
          requiredReportParameters.isIncludeInactiveUsersRequired = true;
          return requiredReportParameters;
        },
      };
    case "user-export":
      return {
        getPermissions: () => ["ReportsUserExport"],
        reportTitle: "User Export",
        getReportTrdp: () => "UserExport.trdp",
        getReportParameters: (reportParameters) => ({
          DistrictId: reportParameters.districtId,
          Inactive: reportParameters.includeInactiveUsers,
          isd: reportParameters.includeDirectors,
          isn: reportParameters.includeNietAdministrators,
          OrganizationId: reportParameters.organizationId,
          SchoolId: reportParameters.schoolId,
        }),
        getReportParametersDisplay: (reportParametersDisplay) => {
          reportParametersDisplay.isOrganizationDisplayed = true;
          reportParametersDisplay.isDistrictDisplayed = true;
          reportParametersDisplay.isSchoolDisplayed = true;
          reportParametersDisplay.isIncludeInactiveUsersDisplayed = true;
          reportParametersDisplay.isIncludeNietAdministratorsDisplayed = true;
          reportParametersDisplay.isIncludeDirectorsDisplayed = true;
          return reportParametersDisplay;
        },
        getRequiredReportParameters: (requiredReportParameters) => {
          requiredReportParameters.isOrganizationIdRequired = true;
          requiredReportParameters.isIncludeInactiveUsersRequired = true;
          return requiredReportParameters;
        },
      };

    case "average-observer-vs-self-score":
      return {
        getPermissions: () => ["ReportsAverageObserverVsSelfScore"],
        reportTitle: "Average Observer vs Self Score",
        getReportTrdp: () => "AverageObserverVsSelfScore.trdp",
        getReportParameters: (reportParameters) => ({
          ClusterId: reportParameters.clusterId,
          DateRangeEnd: reportParameters.endDate,
          DateRangeStart: reportParameters.startDate,
          DistrictId: reportParameters.districtId,
          Individually: reportParameters.isSummarizeAtLevel === false,
          OrganizationId: reportParameters.organizationId,
          SchoolId: reportParameters.schoolId,
          Uids: reportParameters.teacherUserIds,
          SchoolYear: reportParameters.schoolYear
        }),
        getReportParametersDisplay: (reportParametersDisplay) => {
          reportParametersDisplay.isOrganizationDisplayed = true;
          reportParametersDisplay.isDistrictDisplayed = true;
          reportParametersDisplay.isSchoolDisplayed = true;
          reportParametersDisplay.isClusterDisplayed = true;
          reportParametersDisplay.isSchoolYearDisplayed = true;
          reportParametersDisplay.isSelectDateRangeDisplayed = true;
          reportParametersDisplay.isSummarizeAtLevelDisplayed = true;
          reportParametersDisplay.isStartDateDisplayed = true;
          reportParametersDisplay.isEndDateDisplayed = true;
          reportParametersDisplay.isTeachersDisplayed = true;
          return reportParametersDisplay;
        },
        getRequiredReportParameters: (requiredReportParameters) => {
          requiredReportParameters.isOrganizationIdRequired = true;
          //requiredReportParameters.isStartDateRequired = true;
          //requiredReportParameters.isEndDateRequired = true;
          requiredReportParameters.isSummarizeAtLevelRequired = true;
          return requiredReportParameters;
        },
      };
    case "compass-equivalent":
      return {
        getPermissions: () => ["ReportsCompassEquivalent"],
        reportTitle: "Compass Equivalent",
        getReportTrdp: () => "CompassEquivalent.trdp",
        getReportParameters: (reportParameters) => ({
          DistrictId: reportParameters.districtId,
          Inactive: reportParameters.includeInactiveUsers,
          OrganizationId: reportParameters.organizationId,
          SchoolId: reportParameters.schoolId,
          SchoolYear: reportParameters.schoolYear,
        }),
        getReportParametersDisplay: (reportParametersDisplay) => {
          reportParametersDisplay.isOrganizationDisplayed = true;
          reportParametersDisplay.isDistrictDisplayed = true;
          reportParametersDisplay.isSchoolDisplayed = true;
          reportParametersDisplay.isSchoolYearDisplayed = true;
          reportParametersDisplay.isIncludeInactiveUsersDisplayed = true;
          return reportParametersDisplay;
        },
        getRequiredReportParameters: (requiredReportParameters) => {
          requiredReportParameters.isOrganizationIdRequired = true;
          //requiredReportParameters.isSchoolYearRequired = true;
          requiredReportParameters.isIncludeInactiveUsersRequired = true;
          return requiredReportParameters;
        },
      };
    case "educator-average-by-rubric-domain":
      return {
        getPermissions: () => ["ReportsEducatorAverageByRubricDomain"],
        reportTitle: "Educator Average by Rubric Domain",
        getReportTrdp: () => "EducatorAverageByRubricDomain.trdp",
        getReportParameters: (reportParameters) => ({
          ClusterId: reportParameters.clusterId,
          DateRangeEnd: reportParameters.endDate,
          DateRangeStart: reportParameters.startDate,
          DistrictId: reportParameters.districtId,
          Individually: reportParameters.isSummarizeAtLevel === false,
          OrganizationId: reportParameters.organizationId,
          SchoolId: reportParameters.schoolId,
          SchoolYear: reportParameters.schoolYear,
          Uids: reportParameters.teacherUserIds,
          Inactive: reportParameters.includeInactiveUsers
        }),
        getReportParametersDisplay: (reportParametersDisplay) => {
          reportParametersDisplay.isOrganizationDisplayed = true;
          reportParametersDisplay.isDistrictDisplayed = true;
          reportParametersDisplay.isSchoolDisplayed = true;
          reportParametersDisplay.isClusterDisplayed = true;
          reportParametersDisplay.isSchoolYearDisplayed = true;
          reportParametersDisplay.isSelectDateRangeDisplayed = true;
          reportParametersDisplay.isSummarizeAtLevelDisplayed = true;
          reportParametersDisplay.isStartDateDisplayed = true;
          reportParametersDisplay.isEndDateDisplayed = true;
          reportParametersDisplay.isTeachersDisplayed = true;
          reportParametersDisplay.isIncludeInactiveUsersDisplayed = true;
          return reportParametersDisplay;
        },
        getRequiredReportParameters: (requiredReportParameters) => {
          requiredReportParameters.isOrganizationIdRequired = true;
          //requiredReportParameters.isStartDateRequired = true;
          //requiredReportParameters.isEndDateRequired = true;
          requiredReportParameters.isSummarizeAtLevelRequired = true;
          requiredReportParameters.isIncludeInactiveUsersDisplayed = true;
          return requiredReportParameters;
        },
      };
    case "historical-averages-by-educator":
      return {
        getPermissions: () => ["ReportsHistoricalAveragesByEducator"],
        reportTitle: "Historical Averages by Educator",
        getReportTrdp: () => "HistoricalAveragesByEducator.trdp",
        getReportParameters: (reportParameters) => ({
          ClusterId: reportParameters.clusterId,
          DateRangeEnd: reportParameters.endDate,
          DateRangeStart: reportParameters.startDate,
          DistrictId: reportParameters.districtId,
          Individually: reportParameters.isSummarizeAtLevel === false,
          OrganizationId: reportParameters.organizationId,
          SchoolId: reportParameters.schoolId,
          SchoolYear: reportParameters.schoolYear,
          UserIds: reportParameters.teacherUserIds
        }),
        getReportParametersDisplay: (reportParametersDisplay) => {
          reportParametersDisplay.isOrganizationDisplayed = true;
          reportParametersDisplay.isDistrictDisplayed = true;
          reportParametersDisplay.isSchoolDisplayed = true;
          reportParametersDisplay.isClusterDisplayed = true;
          reportParametersDisplay.isSchoolYearDisplayed = true;
          reportParametersDisplay.isSelectDateRangeDisplayed = true;
          reportParametersDisplay.isSummarizeAtLevelDisplayed = true;
          reportParametersDisplay.isStartDateDisplayed = true;
          reportParametersDisplay.isEndDateDisplayed = true;
          reportParametersDisplay.isTeachersDisplayed = true;
          return reportParametersDisplay;
        },
        getRequiredReportParameters: (requiredReportParameters) => {
          requiredReportParameters.isOrganizationIdRequired = true;
          //requiredReportParameters.isStartDateRequired = true;
          //requiredReportParameters.isEndDateRequired = true;
          requiredReportParameters.isSummarizeAtLevelRequired = true;
          return requiredReportParameters;
        },
      };
    case "observation-refinement-or-reinforcement-descriptors":
      return {
        getPermissions: () => [
          "ReportsObservationRefinementReinforcementDescriptors",
        ],
        reportTitle: "Observation Refinement & Reinforcement Descriptors",
        getReportTrdp: () =>
          "ObservationRefinementReinforcementDescriptors.trdp",
        getReportParameters: (reportParameters) => ({
          ClusterId: reportParameters.clusterId,
          DateRangeEnd: reportParameters.endDate,
          DateRangeStart: reportParameters.startDate,
          DistrictId: reportParameters.districtId,
          Individually: reportParameters.isSummarizeAtLevel === false,
          OrganizationId: reportParameters.organizationId,
          SchoolId: reportParameters.schoolId,
          UserIds: reportParameters.teacherUserIds,
          SchoolYear: reportParameters.schoolYear,
        }),
        getReportParametersDisplay: (reportParametersDisplay) => {
          reportParametersDisplay.isOrganizationDisplayed = true;
          reportParametersDisplay.isDistrictDisplayed = true;
          reportParametersDisplay.isSchoolDisplayed = true;
          reportParametersDisplay.isClusterDisplayed = true;
          reportParametersDisplay.isSchoolYearDisplayed = true;
          reportParametersDisplay.isSelectDateRangeDisplayed = true;
          reportParametersDisplay.isSummarizeAtLevelDisplayed = true;
          reportParametersDisplay.isStartDateDisplayed = true;
          reportParametersDisplay.isEndDateDisplayed = true;
          reportParametersDisplay.isTeachersDisplayed = true;
          return reportParametersDisplay;
        },
        getRequiredReportParameters: (requiredReportParameters) => {
          requiredReportParameters.isOrganizationIdRequired = true;
          //requiredReportParameters.isStartDateRequired = true;
          //requiredReportParameters.isEndDateRequired = true;
          requiredReportParameters.isSummarizeAtLevelRequired = true;
          return requiredReportParameters;
        },
      };
    case "observation-status":
      return {
        getPermissions: () => ["ReportsTeacherObservationStatus"],
        reportTitle: "Teacher Observation Status",
        getReportTrdp: () => "TeacherObservationStatus.trdp",
        getReportParameters: (reportParameters) => ({
          DistrictId: reportParameters.districtId,
          OrganizationId: reportParameters.organizationId,
          SchoolId: reportParameters.schoolId,
          SchoolYear: reportParameters.schoolYear,
        }),
        getReportParametersDisplay: (reportParametersDisplay) => {
          reportParametersDisplay.isOrganizationDisplayed = true;
          reportParametersDisplay.isDistrictDisplayed = true;
          reportParametersDisplay.isSchoolDisplayed = true;
          reportParametersDisplay.isSchoolYearDisplayed = true;
          return reportParametersDisplay;
        },
        getRequiredReportParameters: (requiredReportParameters) => {
          requiredReportParameters.isOrganizationIdRequired = true;
          //requiredReportParameters.isSchoolYearRequired = true;
          return requiredReportParameters;
        },
      };
    case "observation-summary-by-educator":
      return {
        getPermissions: () => ["ReportsObservationSummaryByEducator"],
        reportTitle: "Observation Summary by Educator",
        getReportTrdp: () => "ObservationSummaryByEducator.trdp",
        getReportParameters: (reportParameters) => ({
          ClusterId: reportParameters.clusterId,
          DateRangeEnd: reportParameters.endDate,
          DateRangeStart: reportParameters.startDate,
          DistrictId: reportParameters.districtId,
          Individually: reportParameters.isSummarizeAtLevel === false,
          OrganizationId: reportParameters.organizationId,
          SchoolId: reportParameters.schoolId,
          SchoolYear: reportParameters.schoolYear,
          Uids: reportParameters.teacherUserIds
        }),
        getReportParametersDisplay: (reportParametersDisplay) => {
          reportParametersDisplay.isOrganizationDisplayed = true;
          reportParametersDisplay.isDistrictDisplayed = true;
          reportParametersDisplay.isSchoolDisplayed = true;
          reportParametersDisplay.isClusterDisplayed = true;
          reportParametersDisplay.isSchoolYearDisplayed = true;
          reportParametersDisplay.isSelectDateRangeDisplayed = true;
          reportParametersDisplay.isSummarizeAtLevelDisplayed = true;
          reportParametersDisplay.isStartDateDisplayed = true;
          reportParametersDisplay.isEndDateDisplayed = true;
          reportParametersDisplay.isTeachersDisplayed = true;
          return reportParametersDisplay;
        },
        getRequiredReportParameters: (requiredReportParameters) => {
          requiredReportParameters.isOrganizationIdRequired = true;
          //requiredReportParameters.isStartDateRequired = true;
          //requiredReportParameters.isEndDateRequired = true;
          requiredReportParameters.isSummarizeAtLevelRequired = true;
          return requiredReportParameters;
        },
      };
    case "observer-average-by-rubric-domain":
      return {
        getPermissions: () => ["ReportsObserverAverageByRubricDomain"],
        reportTitle: "Observer Average by Rubric Domain",
        getReportTrdp: () => "ObserverAverageByRubricDomain.trdp",
        getReportParameters: (reportParameters) => ({
          ClusterId: reportParameters.clusterId,
          DateRangeEnd: reportParameters.endDate,
          DateRangeStart: reportParameters.startDate,
          DistrictId: reportParameters.districtId,
          Individually: reportParameters.isSummarizeAtLevel === false,
          OrganizationId: reportParameters.organizationId,
          SchoolId: reportParameters.schoolId,
          SchoolYear: reportParameters.schoolYear,
          UIds: reportParameters.teacherUserIds,
        }),
        getReportParametersDisplay: (reportParametersDisplay) => {
          reportParametersDisplay.isOrganizationDisplayed = true;
          reportParametersDisplay.isDistrictDisplayed = true;
          reportParametersDisplay.isSchoolDisplayed = true;
          reportParametersDisplay.isClusterDisplayed = true;
          reportParametersDisplay.isSchoolYearDisplayed = true;
          reportParametersDisplay.isSelectDateRangeDisplayed = true;
          reportParametersDisplay.isSummarizeAtLevelDisplayed = true;
          reportParametersDisplay.isStartDateDisplayed = true;
          reportParametersDisplay.isEndDateDisplayed = true;
          reportParametersDisplay.isTeachersDisplayed = true;
          return reportParametersDisplay;
        },
        getRequiredReportParameters: (requiredReportParameters) => {
          requiredReportParameters.isOrganizationIdRequired = true;
          //requiredReportParameters.isStartDateRequired = true;
          //requiredReportParameters.isEndDateRequired = true;
          requiredReportParameters.isSummarizeAtLevelRequired = true;
          return requiredReportParameters;
        },
      };
    case "overall-average-by-school-administrator":
      return {
        getPermissions: () => ["ReportsOverallAverageBySchoolAdministrator"],
        reportTitle: "Overall Average by School Administrator",
        getReportTrdp: () => "OverallAverageBySchoolAdministrator.trdp",
        getReportParameters: (reportParameters) => ({
          DateRangeEnd: reportParameters.endDate,
          DateRangeStart: reportParameters.startDate,
          DistrictId: reportParameters.districtId,
          Individually: reportParameters.isSummarizeAtLevel === false,
          OrganizationId: reportParameters.organizationId,
          SchoolId: reportParameters.schoolId,
          SchoolYear: reportParameters.schoolYear,
          Uids: reportParameters.teacherUserIds,
        }),
        getReportParametersDisplay: (reportParametersDisplay) => {
          reportParametersDisplay.isOrganizationDisplayed = true;
          reportParametersDisplay.isDistrictDisplayed = true;
          reportParametersDisplay.isSchoolDisplayed = true;
          reportParametersDisplay.isSchoolYearDisplayed = true;
          reportParametersDisplay.isSelectDateRangeDisplayed = true;
          reportParametersDisplay.isSummarizeAtLevelDisplayed = true;
          reportParametersDisplay.isStartDateDisplayed = true;
          reportParametersDisplay.isAdminDisplayed = true;
          reportParametersDisplay.isEndDateDisplayed = true;
          return reportParametersDisplay;
        },
        getRequiredReportParameters: (requiredReportParameters) => {
          requiredReportParameters.isOrganizationIdRequired = true;
          //requiredReportParameters.isStartDateRequired = true;
          //requiredReportParameters.isEndDateRequired = true;
          requiredReportParameters.isSummarizeAtLevelRequired = true;
          return requiredReportParameters;
        },
      };
    case "school-administrator-payout":
      return {
        getPermissions: () => [""],
        reportTitle: "School Administrator Payout",
        getReportTrdp: () => "SchoolAdministratorPayout.trdp",
        getReportParameters: (reportParameters) => ({
          DistrictID: reportParameters.districtId,
          SchoolYear: reportParameters.schoolYear,
        }),
        getReportParametersDisplay: (reportParametersDisplay) => {
          reportParametersDisplay.isOrganizationDisplayed = true;
          reportParametersDisplay.isDistrictDisplayed = true;
          reportParametersDisplay.isSchoolYearDisplayed = true;
          return reportParametersDisplay;
        },
        getRequiredReportParameters: (requiredReportParameters) => {
          requiredReportParameters.isDistrictIdRequired = true;
          //requiredReportParameters.isSchoolYearRequired = true;
          return requiredReportParameters;
        },
      };
    case "school-administrator-average-by-rubric-domain":
      return {
        getPermissions: () => [
          "ReportsSchoolAdministratorAverageByRubricDomain",
        ],
        reportTitle: "School Administrator Average by Rubric Domain",
        getReportTrdp: () => "SchoolAdministratorAverageByRubricDomain.trdp",
        getReportParameters: (reportParameters) => ({
          DateRangeEnd: reportParameters.endDate,
          DateRangeStart: reportParameters.startDate,
          DistrictId: reportParameters.districtId,
          Individually: reportParameters.isSummarizeAtLevel === false,
          OrganizationId: reportParameters.organizationId,
          SchoolId: reportParameters.schoolId,
          SchoolYear: reportParameters.schoolYear,
          Uids: reportParameters.teacherUserIds,
          Inactive: reportParameters.includeInactiveUsers
        }),
        getReportParametersDisplay: (reportParametersDisplay) => {
          reportParametersDisplay.isOrganizationDisplayed = true;
          reportParametersDisplay.isDistrictDisplayed = true;
          reportParametersDisplay.isSchoolDisplayed = true;
          reportParametersDisplay.isSchoolYearDisplayed = true;
          reportParametersDisplay.isSelectDateRangeDisplayed = true;
          reportParametersDisplay.isSummarizeAtLevelDisplayed = true;
          reportParametersDisplay.isStartDateDisplayed = true;
          reportParametersDisplay.isEndDateDisplayed = true;
          reportParametersDisplay.isAdminDisplayed = true;
          reportParametersDisplay.isIncludeInactiveUsersDisplayed = true;
          return reportParametersDisplay;
        },
        getRequiredReportParameters: (requiredReportParameters) => {
          requiredReportParameters.isOrganizationIdRequired = true;
          //requiredReportParameters.isStartDateRequired = true;
          //requiredReportParameters.isEndDateRequired = true;
          requiredReportParameters.isSummarizeAtLevelRequired = true;
          requiredReportParameters.isIncludeInactiveUsersDisplayed = true;
          return requiredReportParameters;
        },
      };
    case "school-administrator-end-of-year-rating":
      return {
        getPermissions: () => [
          "ReportsSchoolAdministratorEndOfYearRatingRounding",
          "ReportsSchoolAdministratorEndOfYearRatingNoRounding"],
        reportTitle: "School Administrator End of Year Rating",
        getReportType: (reportTypeIndex) => {
          switch (reportTypeIndex) {
            case "0":
              return "rounding";
            case "1":
              return "no-rounding";
            default:
              return "";
          }
        },
        getReportTrdp: () => "SchoolAdministratorEndOfYearRating.trdp",
        getReportParameters: (reportParameters, reportType) => ({
          DistrictId: reportParameters.districtId,
          IsRounded: reportType === "rounding",
          OrganizationId: reportParameters.organizationId,
          SchoolId: reportParameters.schoolId,
          SchoolYear: reportParameters.schoolYear,
          IsNIETAdmin: ''
        }),
        getReportParametersDisplay: (reportParametersDisplay) => {
          reportParametersDisplay.isRoundingTypeDisplayed = true;
          reportParametersDisplay.isOrganizationDisplayed = true;
          reportParametersDisplay.isDistrictDisplayed = true;
          reportParametersDisplay.isSchoolDisplayed = true;
          reportParametersDisplay.isSchoolYearDisplayed = true;
          return reportParametersDisplay;
        },
        getRequiredReportParameters: (requiredReportParameters) => {
          requiredReportParameters.isReportTypeRequired = true;
          requiredReportParameters.isOrganizationIdRequired = true;
          //requiredReportParameters.isSchoolYearRequired = true;
          return requiredReportParameters;
        },
      };
    case "school-administrator-individual-summary":
      return {
        getPermissions: () => ["ReportsSchoolAdministratorIndividualSummary"],
        reportTitle: "School Administrator Individual Summary",
        getReportTrdp: () => "SchoolAdministratorIndividualSummary.trdp",
        getReportParameters: (reportParameters) => ({
          DistrictId: reportParameters.districtId,
          OrganizationId: reportParameters.organizationId,
          SchoolId: reportParameters.schoolId,
          SchoolYear: reportParameters.schoolYear,
          Uids: reportParameters.teacherUserIds,
        }),
        getReportParametersDisplay: (reportParametersDisplay) => {
          reportParametersDisplay.isOrganizationDisplayed = true;
          reportParametersDisplay.isDistrictDisplayed = true;
          reportParametersDisplay.isSchoolDisplayed = true;
          reportParametersDisplay.isSchoolYearDisplayed = true;
          reportParametersDisplay.isAdminDisplayed = true;
          return reportParametersDisplay;
        },
        getRequiredReportParameters: (requiredReportParameters) => {
          requiredReportParameters.isOrganizationIdRequired = true;
          //requiredReportParameters.isSchoolYearRequired = true;
          return requiredReportParameters;
        },
      };
    case "leadership-log-categories":
      return {
        getPermissions: () => ["ReportsLeadershipLogCategories"],
        reportTitle: "Leadership Log Categories",
        getReportTrdp: () => "LeadershipLogCategories.trdp",
        getReportParameters: (reportParameters) => ({
          DateRangeEnd: reportParameters.endDate,
          DateRangeStart: reportParameters.startDate,
          DistrictId: reportParameters.districtId,
          Individually: reportParameters.isSummarizeAtLevel === false,
          OrganizationId: reportParameters.organizationId,
          SchoolId: reportParameters.schoolId,
          SchoolYear: reportParameters.schoolYear
        }),
        getReportParametersDisplay: (reportParametersDisplay) => {
          reportParametersDisplay.isOrganizationDisplayed = true;
          reportParametersDisplay.isDistrictDisplayed = true;
          reportParametersDisplay.isSchoolDisplayed = true;
          reportParametersDisplay.isSchoolYearDisplayed = true;
          reportParametersDisplay.isSelectDateRangeDisplayed = true;
          reportParametersDisplay.isSummarizeAtLevelDisplayed = true;
          reportParametersDisplay.isStartDateDisplayed = true;
          reportParametersDisplay.isEndDateDisplayed = true;
          return reportParametersDisplay;
        },
        getRequiredReportParameters: (requiredReportParameters) => {
          requiredReportParameters.isOrganizationIdRequired = true;
          requiredReportParameters.isSummarizeAtLevelRequired = true;
          return requiredReportParameters;
        },
      };
    case "school-administrator-observation-summary":
      return {
        getPermissions: () => ["ReportsSchoolAdministratorObservationSummary"],
        reportTitle: "School Administrator Observation Summary",
        getReportTrdp: () => "SchoolAdministratorObservationSummary.trdp",
        getReportParameters: (reportParameters) => ({
          DateRangeEnd: reportParameters.endDate,
          DateRangeStart: reportParameters.startDate,
          DistrictId: reportParameters.districtId,
          Individually: reportParameters.isSummarizeAtLevel === false,
          OrganizationId: reportParameters.organizationId,
          SchoolId: reportParameters.schoolId,
          SchoolYear: reportParameters.schoolYear,
          Uids: reportParameters.teacherUserIds
        }),
        getReportParametersDisplay: (reportParametersDisplay) => {
          reportParametersDisplay.isOrganizationDisplayed = true;
          reportParametersDisplay.isDistrictDisplayed = true;
          reportParametersDisplay.isSchoolDisplayed = true;
          reportParametersDisplay.isSchoolYearDisplayed = true;
          reportParametersDisplay.isSelectDateRangeDisplayed = true;
          reportParametersDisplay.isSummarizeAtLevelDisplayed = true;
          reportParametersDisplay.isStartDateDisplayed = true;
          reportParametersDisplay.isEndDateDisplayed = true;
          reportParametersDisplay.isAdminDisplayed = true;
          return reportParametersDisplay;
        },
        getRequiredReportParameters: (requiredReportParameters) => {
          requiredReportParameters.isOrganizationIdRequired = true;
          //requiredReportParameters.isStartDateRequired = true;
          //requiredReportParameters.isEndDateRequired = true;
          requiredReportParameters.isSummarizeAtLevelRequired = true;
          return requiredReportParameters;
        },
      };
    case "school-administrator-refinement-and-reinforcement-goals":
      return {
        getPermissions: () => [
          "ReportsSchoolAdministratorRefinementAndReinforcementGoalsRefinement",
          "ReportsSchoolAdministratorRefinementAndReinforcementGoalsReinforcement"
        ],
        reportTitle: "School Administrator Refinement and Reinforcement Goals",
        getReportType: (reportTypeIndex) => {
          switch (reportTypeIndex) {
            case "0":
              return "refinement";
            case "1":
              return "reinforcement";
            default:
              return "";
          }
        },
        getReportTrdp: (reportType) =>
          reportType === "refinement"
            ? "SchoolAdministratorRefinementGoals.trdp"
            : "SchoolAdministratorReinforcementGoals.trdp",
        getReportParameters: (reportParameters) => ({
          DateRangeEnd: reportParameters.endDate,
          DateRangeStart: reportParameters.startDate,
          DistrictId: reportParameters.districtId,
          Individually: reportParameters.isSummarizeAtLevel === false,
          OrganizationId: reportParameters.organizationId,
          SchoolId: reportParameters.schoolId,
          SchoolYear: reportParameters.schoolYear,
          Uids: reportParameters.teacherUserIds,
        }),
        getReportParametersDisplay: (reportParametersDisplay) => {
          reportParametersDisplay.isRefinementOrReinforcementTypeDisplayed = true;
          reportParametersDisplay.isOrganizationDisplayed = true;
          reportParametersDisplay.isDistrictDisplayed = true;
          reportParametersDisplay.isSchoolDisplayed = true;
          reportParametersDisplay.isSchoolYearDisplayed = true;
          reportParametersDisplay.isSelectDateRangeDisplayed = true;
          reportParametersDisplay.isSummarizeAtLevelDisplayed = true;
          reportParametersDisplay.isStartDateDisplayed = true;
          reportParametersDisplay.isAdminDisplayed = true;
          reportParametersDisplay.isEndDateDisplayed = true;
          return reportParametersDisplay;
        },
        getRequiredReportParameters: (requiredReportParameters) => {
          requiredReportParameters.isOrganizationIdRequired = true;
          //requiredReportParameters.isStartDateRequired = true;
          //requiredReportParameters.isEndDateRequired = true;
          requiredReportParameters.isSummarizeAtLevelRequired = true;
          return requiredReportParameters;
        },
      };
    case "school-administrator-survey-report":
      return {
        getPermissions: () => ["ReportsSchoolAdministratorSurveyReport"],
        reportTitle: "School Administrator Survey Report",
        getReportTrdp: () => "SchoolAdministratorSurveyReport.trdp",
        getReportParameters: (reportParameters) => ({
          DistrictId: reportParameters.districtId,
          OrganizationId: reportParameters.organizationId,
          SchoolId: reportParameters.schoolId,
          SchoolYear: reportParameters.schoolYear,
        }),
        getReportParametersDisplay: (reportParametersDisplay) => {
          reportParametersDisplay.isOrganizationDisplayed = true;
          reportParametersDisplay.isDistrictDisplayed = true;
          reportParametersDisplay.isSchoolDisplayed = true;
          reportParametersDisplay.isSchoolYearDisplayed = true;
          return reportParametersDisplay;
        },
        getRequiredReportParameters: (requiredReportParameters) => {
          requiredReportParameters.isOrganizationIdRequired = true;
          //requiredReportParameters.isSchoolYearRequired = true;
          return requiredReportParameters;
        },
      };
    case "teacher-evaluator-certification":
      return {
        getPermissions: () => [
          "ReportsTeacherEvaluatorCertificationStatus",
          "ReportsTeacherEvaluatorCertificationResets",
          "ReportsTeacherEvaluatorCertificationFailures",
          "ReportsTeacherEvaluatorCertificationResults"],
        reportTitle: "Teacher Evaluator Certification",
        getReportType: (reportTypeIndex) => {
          switch (reportTypeIndex) {
            case "0":
              return "status";
            case "1":
              return "resets";
            case "2":
              return "failures";
            case "3":
              return "results";
            default:
              return "";
          }
        },
        getReportTrdp: (reportType) => {
          switch (reportType) {
            case "status":
              return "TeacherEvaluatorCertificationStatus_NIET.trdp";
            case "resets":
              return "TeacherEvaluatorCertificationResets_NIET.trdp";
            case "failures":
              return "TeacherEvaluatorCertificationFailures_NIET.trdp";
            case "results":
              return "TeacherEvaluatorCertificationResults_NIET.trdp";
            default:
              return "";
          }
        },
        getReportParameters: (reportParameters, reportType) => {
          switch (reportType) {
            case "status":
              return {
                DistrictId: reportParameters.districtId,
                Inactive: reportParameters.includeInactiveUsers,
                isd: reportParameters.includeDirectors,
                isn: reportParameters.includeNietAdministrators,
                OrganizationId: reportParameters.organizationId,
                SchoolId: reportParameters.schoolId,
                SchoolYear: reportParameters.schoolYear,
              };
            case "resets":
            case "failures":
            case "results":
              return {
                DistrictId: reportParameters.districtId,
                OrganizationId: reportParameters.organizationId,
                SchoolId: reportParameters.schoolId,
                SchoolYear: reportParameters.schoolYear,
              };
            default:
              return {};
          }
        },
        getReportParametersDisplay: (reportParametersDisplay, reportType) => {
          reportParametersDisplay.isEvaluatorCertificationTypeDisplayed = true;
          reportParametersDisplay.isOrganizationDisplayed = true;
          reportParametersDisplay.isDistrictDisplayed = true;
          reportParametersDisplay.isSchoolDisplayed = true;
          reportParametersDisplay.isSchoolYearDisplayed = true;

          if (reportType === "status") {
            reportParametersDisplay.isIncludeInactiveUsersDisplayed = true;
            reportParametersDisplay.isIncludeNietAdministratorsDisplayed = true;
            reportParametersDisplay.isIncludeDirectorsDisplayed = true;
          }
          return reportParametersDisplay;
        },
        getRequiredReportParameters: (requiredReportParameters, reportType) => {
          requiredReportParameters.isReportTypeRequired = true;
          requiredReportParameters.isOrganizationIdRequired = true;
          //requiredReportParameters.isSchoolYearRequired = true;

          if (reportType === "status") {
            requiredReportParameters.isIncludeInactiveUsersRequired = true;
          }
          return requiredReportParameters;
        },
      };
    case "teacher-evaluator-certification-status":
      return {
        getPermissions: () => ["ReportsTeacherEvaluatorCertificationStatus"],
        reportTitle: "Teacher Evaluator Certification Status",
        getReportTrdp: () => { return "TeacherEvaluatorCertificationStatus_NIET.trdp"; },
        getReportParameters: (reportParameters) => {
          return {
            DistrictId: reportParameters.districtId,
            Inactive: reportParameters.includeInactiveUsers,
            isd: reportParameters.includeDirectors,
            isn: reportParameters.includeNietAdministrators,
            OrganizationId: reportParameters.organizationId,
            SchoolId: reportParameters.schoolId,
            SchoolYear: reportParameters.schoolYear,
          };
        },
        getReportParametersDisplay: (reportParametersDisplay) => {
          reportParametersDisplay.isEvaluatorCertificationTypeDisplayed = false;
          reportParametersDisplay.isOrganizationDisplayed = true;
          reportParametersDisplay.isDistrictDisplayed = true;
          reportParametersDisplay.isSchoolDisplayed = true;
          reportParametersDisplay.isSchoolYearDisplayed = true;
          reportParametersDisplay.isIncludeInactiveUsersDisplayed = true;
          reportParametersDisplay.isIncludeNietAdministratorsDisplayed = true;
          reportParametersDisplay.isIncludeDirectorsDisplayed = true;
          return reportParametersDisplay;
        },
        getRequiredReportParameters: (requiredReportParameters) => {
          requiredReportParameters.isReportTypeRequired = true;
          requiredReportParameters.isOrganizationIdRequired = true;
          requiredReportParameters.isIncludeInactiveUsersRequired = true;
          return requiredReportParameters;
        },
      };
    case "evaluator-certification-records-export":
      return {
        getPermissions: () => [
          "ReportsTeacherEvaluatorCertificationRecordsExport",
          "ReportsSchoolAdministratorEvaluatorCertificationRecordsExport",
        ],
        reportTitle: "Evaluator Certification Records Export",
        getReportType: (reportTypeIndex) => {
          switch (reportTypeIndex) {
            case "0":
              return "teacher";
            case "1":
              return "school-administrator";
            default:
              return "";
          }
        },
        getReportTrdp: (reportType) =>
          reportType === "teacher"
            ? "TeacherEvaluatorCertificationRecordsExport.trdp"
            : "SchoolAdministratorEvaluatorCertificationRecordsExport.trdp",
        getReportParameters: (reportParameters) => ({
          DistrictId: reportParameters.districtId,
          Inactive: reportParameters.includeInactiveUsers,
          isd: reportParameters.includeDirectors,
          isn: reportParameters.includeNietAdministrators,
          OrganizationId: reportParameters.organizationId,
          SchoolId: reportParameters.schoolId,
          SchoolYear: reportParameters.schoolYear,
        }),
        getReportParametersDisplay: (reportParametersDisplay) => {
          reportParametersDisplay.isTeacherOrSchoolAdministratorTypeDisplayed = true;
          reportParametersDisplay.isOrganizationDisplayed = true;
          reportParametersDisplay.isDistrictDisplayed = true;
          reportParametersDisplay.isSchoolDisplayed = true;
          reportParametersDisplay.isSchoolYearDisplayed = true;
          reportParametersDisplay.isIncludeInactiveUsersDisplayed = true;
          reportParametersDisplay.isIncludeNietAdministratorsDisplayed = true;
          reportParametersDisplay.isIncludeDirectorsDisplayed = true;
          return reportParametersDisplay;
        },
        getRequiredReportParameters: (requiredReportParameters) => {
          requiredReportParameters.isOrganizationIdRequired = true;
          //requiredReportParameters.isSchoolYearRequired = true;
          requiredReportParameters.isIncludeInactiveUsersRequired = true;
          return requiredReportParameters;
        },
      };
    case "evaluator-certification-statistics":
      return {
        getPermissions: () => [
          "ReportsTeacherEvaluatorCertificationStatistics",
          "ReportsSchoolAdministratorEvaluatorCertificationStatistics",
        ],
        reportTitle: "Evaluator Certification Statistics",
        getReportType: (reportTypeIndex) => {
          switch (reportTypeIndex) {
            case "0":
              return "teacher";
            case "1":
              return "school-administrator";
            default:
              return "";
          }
        },
        getReportTrdp: (reportType) =>
          reportType === "teacher"
            ? "TeacherEvaluatorCertificationStatistics.trdp"
            : "SchoolAdministratorEvaluatorCertificationStatistics.trdp",
        getReportParameters: (reportParameters) => ({
          DistrictID: reportParameters.districtId,
          OrganizationID: reportParameters.organizationId,
          SchoolID: reportParameters.schoolId,
          SchoolYear: reportParameters.schoolYear,
        }),
        getReportParametersDisplay: (reportParametersDisplay) => {
          reportParametersDisplay.isTeacherOrSchoolAdministratorTypeDisplayed = true;
          reportParametersDisplay.isOrganizationDisplayed = true;
          reportParametersDisplay.isDistrictDisplayed = true;
          reportParametersDisplay.isSchoolDisplayed = true;
          reportParametersDisplay.isSchoolYearDisplayed = true;
          return reportParametersDisplay;
        },
        getRequiredReportParameters: (requiredReportParameters) => {
          requiredReportParameters.isOrganizationIdRequired = true;
          //requiredReportParameters.isSchoolYearRequired = true;
          return requiredReportParameters;
        },
      };
    case "teacher-grade-level-average-by-rubric-domain":
      return {
        getPermissions: () => [
          "ReportsTeacherGradeLevelAveragesByRubricDomain",
        ],
        reportTitle: "Teacher Grade Level Averages by Rubric Domain",
        getReportTrdp: () => "TeacherGradeLevelAverageByRubricDomain.trdp",
        getReportParameters: (reportParameters) => ({
          ClusterID: reportParameters.clusterId,
          DateRangeEnd: reportParameters.endDate,
          DateRangeStart: reportParameters.startDate,
          DistrictID: reportParameters.districtId,
          SchoolYear: reportParameters.schoolYear,
          Individually: reportParameters.isSummarizeAtLevel === false,
          OrganizationID: reportParameters.organizationId,
          SchoolID: reportParameters.schoolId,
          GradeLevels: reportParameters.gradeLevels,
          UIds: reportParameters.teacherUserIds,
        }),
        getReportParametersDisplay: (reportParametersDisplay) => {
          reportParametersDisplay.isOrganizationDisplayed = true;
          reportParametersDisplay.isDistrictDisplayed = true;
          reportParametersDisplay.isSchoolDisplayed = true;
          reportParametersDisplay.isClusterDisplayed = true;
          reportParametersDisplay.isSchoolYearDisplayed = true;
          reportParametersDisplay.isSelectDateRangeDisplayed = true;
          reportParametersDisplay.isSummarizeAtLevelDisplayed = true;
          reportParametersDisplay.isStartDateDisplayed = true;
          reportParametersDisplay.isEndDateDisplayed = true;
          reportParametersDisplay.isIncludeGradeLevels = true;
          reportParametersDisplay.isTeachersDisplayed = true;
          return reportParametersDisplay;
        },
        getRequiredReportParameters: (requiredReportParameters) => {
          requiredReportParameters.isOrganizationIdRequired = true;
          //requiredReportParameters.isStartDateRequired = true;
          //requiredReportParameters.isEndDateRequired = true;
          requiredReportParameters.isSummarizeAtLevelRequired = true;
          return requiredReportParameters;
        },
      };
    case "teacher-individual-summary":
      return {
        getPermissions: () => ["ReportsTeacherIndividualSummary"],
        reportTitle: "Teacher Individual Summary",
        getReportTrdp: () => "TeacherIndividualSummary.trdp",
        getReportParameters: (reportParameters) => ({
          ClusterId: reportParameters.clusterId,
          DistrictId: reportParameters.districtId,
          Inactive: reportParameters.includeInactiveUsers,
          OrganizationId: reportParameters.organizationId,
          SchoolId: reportParameters.schoolId,
          SchoolYear: reportParameters.schoolYear,
          Uids: reportParameters.teacherUserIds,
          WithOverallAvg: false,
        }),
        getReportParametersDisplay: (reportParametersDisplay) => {
          reportParametersDisplay.isOrganizationDisplayed = true;
          reportParametersDisplay.isDistrictDisplayed = true;
          reportParametersDisplay.isSchoolDisplayed = true;
          reportParametersDisplay.isClusterDisplayed = true;
          reportParametersDisplay.isSchoolYearDisplayed = true;
          reportParametersDisplay.isTeachersDisplayed = true;
          reportParametersDisplay.isIncludeInactiveUsersDisplayed = true;
          return reportParametersDisplay;
        },
        getRequiredReportParameters: (requiredReportParameters) => {
          requiredReportParameters.isOrganizationIdRequired = true;
          //requiredReportParameters.isSchoolYearRequired = true;
          requiredReportParameters.isIncludeInactiveUsersRequired = true;
          return requiredReportParameters;
        },
      };
    case "teacher-individual-summary-with-overall-avg":
      return {
        getPermissions: () => ["ReportsTeacherIndividualSummaryWithOverallAvg"],
        reportTitle: "Teacher Individual Summary with Overall Average",
        getReportTrdp: () => "TeacherIndividualSummary.trdp",
        getReportParameters: (reportParameters) => ({
          ClusterId: reportParameters.clusterId,
          DistrictId: reportParameters.districtId,
          OrganizationId: reportParameters.organizationId,
          SchoolId: reportParameters.schoolId,
          SchoolYear: reportParameters.schoolYear,
          Uids: reportParameters.teacherUserIds,
          WithOverallAvg: true,
          Inactive: false
        }),
        getReportParametersDisplay: (reportParametersDisplay) => {
          reportParametersDisplay.isOrganizationDisplayed = true;
          reportParametersDisplay.isDistrictDisplayed = true;
          reportParametersDisplay.isSchoolDisplayed = true;
          reportParametersDisplay.isClusterDisplayed = true;
          reportParametersDisplay.isSchoolYearDisplayed = true;
          reportParametersDisplay.isTeachersDisplayed = true;
          return reportParametersDisplay;
        },
        getRequiredReportParameters: (requiredReportParameters) => {
          requiredReportParameters.isOrganizationIdRequired = true;
          //requiredReportParameters.isSchoolYearRequired = true;
          return requiredReportParameters;
        },
      };

    case "teacher-overall-average":
      return {
        getPermissions: () => [
          "ReportsTeacherOverallAverageRubricIndicator",
          "ReportsTeacherOverallAverageObserver",
          "ReportsTeacherOverallAverageEducator",
          "ReportsTeacherOverallAverageSubject"
        ],
        reportTitle: "Teacher Overall Average",
        getReportType: (reportTypeIndex) => {
          switch (reportTypeIndex) {
            case "0":
              return "rubric-indicator";
            case "1":
              return "observer";
            case "2":
              return "educator";
            case "3":
              return "subject";
            default:
              return "";
          }
        },
        getReportTrdp: (reportType) => {
          switch (reportType) {
            case "rubric-indicator":
              return "TeacherOverallAverageByRubricIndicator.trdp";
            case "observer":
              return "TeacherOverallAverageByObserver.trdp";
            case "educator":
              return "TeacherOverallAverageByEducator.trdp";
            case "subject":
              return "TeacherOverallAverageBySubject.trdp";
            default:
              return "";
          }
        },
        getReportParameters: (reportParameters) => ({
          ClusterId: reportParameters.clusterId,
          DateRangeEnd: reportParameters.endDate,
          DateRangeStart: reportParameters.startDate,
          SchoolYear: reportParameters.schoolYear,
          DistrictId: reportParameters.districtId,
          Individually: reportParameters.isSummarizeAtLevel === false,
          OrganizationId: reportParameters.organizationId,
          SchoolId: reportParameters.schoolId,
          Uids: reportParameters.teacherUserIds,
        }),
        getReportParametersDisplay: (reportParametersDisplay) => {
          reportParametersDisplay.isTeacherOverallAverageTypeDisplayed = true;
          reportParametersDisplay.isOrganizationDisplayed = true;
          reportParametersDisplay.isDistrictDisplayed = true;
          reportParametersDisplay.isSchoolDisplayed = true;
          reportParametersDisplay.isClusterDisplayed = true;
          reportParametersDisplay.isSchoolYearDisplayed = true;
          reportParametersDisplay.isSelectDateRangeDisplayed = true;
          reportParametersDisplay.isSummarizeAtLevelDisplayed = true;
          reportParametersDisplay.isStartDateDisplayed = true;
          reportParametersDisplay.isEndDateDisplayed = true;
          reportParametersDisplay.isTeachersDisplayed = true;
          return reportParametersDisplay;
        },
        getRequiredReportParameters: (requiredReportParameters) => {
          requiredReportParameters.isReportTypeRequired = true;
          requiredReportParameters.isOrganizationIdRequired = true;
          //requiredReportParameters.isStartDateRequired = true;
          //requiredReportParameters.isEndDateRequired = true;
          requiredReportParameters.isSummarizeAtLevelRequired = true;
          return requiredReportParameters;
        },
      };
    case "teacher-payout":
      return {
        getPermissions: () => ["ReportsTeacherPayout"],
        reportTitle: "Teacher Payout",
        getReportTrdp: () => "TeacherPayout.trdp",
        getReportParameters: (reportParameters) => ({
          SchoolID: reportParameters.schoolId,
          SchoolYear: reportParameters.schoolYear,
        }),
        getReportParametersDisplay: (reportParametersDisplay) => {
          reportParametersDisplay.isOrganizationDisplayed = true;
          reportParametersDisplay.isDistrictDisplayed = true;
          reportParametersDisplay.isSchoolDisplayed = true;
          reportParametersDisplay.isSchoolYearDisplayed = true;
          return reportParametersDisplay;
        },
        getRequiredReportParameters: (requiredReportParameters) => {
          requiredReportParameters.isSchoolIdRequired = true;
          //requiredReportParameters.isSchoolYearRequired = true;
          return requiredReportParameters;
        },
      };
    case "teacher-payout-users":
      return {
        getPermissions: () => ["ReportsTeacherPayoutUsers"],
        reportTitle: "Teacher Payout Users",
        getReportTrdp: () => "TeacherPayoutUsers.trdp",
        getReportParameters: (reportParameters) => ({
          OrganizationId: reportParameters.organizationId,
          SchoolId: reportParameters.schoolId,
          SchoolYear: reportParameters.schoolYear,
        }),
        getReportParametersDisplay: (reportParametersDisplay) => {
          reportParametersDisplay.isOrganizationDisplayed = true;
          reportParametersDisplay.isDistrictDisplayed = true;
          reportParametersDisplay.isSchoolDisplayed = true;
          reportParametersDisplay.isSchoolYearDisplayed = true;
          return reportParametersDisplay;
        },
        getRequiredReportParameters: (requiredReportParameters) => {
          requiredReportParameters.isOrganizationIdRequired = true;
          requiredReportParameters.isSchoolIdRequired = true;
          //requiredReportParameters.isSchoolYearRequired = true;
          return requiredReportParameters;
        },
      };
    case "teacher-refinement-or-reinforcement-goals":
      return {
        getPermissions: () => [
          "ReportsTeacherRefinementAndReinforcementGoalsRefinement",
          "ReportsTeacherRefinementAndReinforcementGoalsReinforcement"],
        reportTitle: "Teacher Refinement and Reinforcement Goals",
        getReportType: (reportTypeIndex) => {
          switch (reportTypeIndex) {
            case "0":
              return "refinement";
            case "1":
              return "reinforcement";
            default:
              return "";
          }
        },
        getReportTrdp: (reportType) =>
          reportType === "refinement"
            ? "TeacherRefinementGoals.trdp"
            : "TeacherReinforcementGoals.trdp",
        getReportParameters: (reportParameters) => ({
          ClusterId: reportParameters.clusterId,
          DateRangeEnd: reportParameters.endDate,
          DateRangeStart: reportParameters.startDate,
          DistrictId: reportParameters.districtId,
          Individually: reportParameters.isSummarizeAtLevel === false,
          OrganizationId: reportParameters.organizationId,
          SchoolId: reportParameters.schoolId,
          Uids: reportParameters.teacherUserIds,
          SchoolYear: reportParameters.schoolYear
        }),
        getReportParametersDisplay: (reportParametersDisplay) => {
          reportParametersDisplay.isRefinementOrReinforcementTypeDisplayed = true;
          reportParametersDisplay.isOrganizationDisplayed = true;
          reportParametersDisplay.isDistrictDisplayed = true;
          reportParametersDisplay.isSchoolDisplayed = true;
          reportParametersDisplay.isClusterDisplayed = true;
          reportParametersDisplay.isSchoolYearDisplayed = true;
          reportParametersDisplay.isSelectDateRangeDisplayed = true;
          reportParametersDisplay.isSummarizeAtLevelDisplayed = true;
          reportParametersDisplay.isStartDateDisplayed = true;
          reportParametersDisplay.isEndDateDisplayed = true;
          reportParametersDisplay.isTeachersDisplayed = true;
          return reportParametersDisplay;
        },
        getRequiredReportParameters: (requiredReportParameters) => {
          requiredReportParameters.isReportTypeRequired = true;
          requiredReportParameters.isOrganizationIdRequired = true;
          //requiredReportParameters.isStartDateRequired = true;
          //requiredReportParameters.isEndDateRequired = true;
          requiredReportParameters.isSummarizeAtLevelRequired = true;
          return requiredReportParameters;
        },
      };
    case "teacher-skr-summary":
      return {
        getPermissions: () => [
          "ReportsTeacherSKRSummaryRounding",
          "ReportsTeacherSKRSummaryNoRounding"],
        reportTitle: "Teacher SKR Summary",
        getReportType: (reportTypeIndex) => {
          switch (reportTypeIndex) {
            case "0":
              return "rounding";
            case "1":
              return "no-rounding";
            default:
              return "";
          }
        },
        getReportTrdp: () => "TeacherSKRSummary.trdp",
        getReportParameters: (reportParameters, reportType) => ({
          ClusterId: reportParameters.clusterId,
          Individually: reportParameters.isSummarizeAtLevel === false,
          IsRounded: reportType === "rounding",
          OrganizationId: reportParameters.organizationId,
          DistrictId: reportParameters.districtId,
          SchoolId: reportParameters.schoolId,
          SchoolYear: reportParameters.schoolYear,
          Inactive: reportParameters.includeInactiveUsers
        }),
        getReportParametersDisplay: (reportParametersDisplay) => {
          reportParametersDisplay.isRoundingTypeDisplayed = true;
          reportParametersDisplay.isOrganizationDisplayed = true;
          reportParametersDisplay.isDistrictDisplayed = true;
          reportParametersDisplay.isSchoolDisplayed = true;
          reportParametersDisplay.isSchoolYearDisplayed = true;
          reportParametersDisplay.isIncludeInactiveUsersDisplayed = true;
          return reportParametersDisplay;
        },
        getRequiredReportParameters: (requiredReportParameters) => {
          requiredReportParameters.isReportTypeRequired = true;
          requiredReportParameters.isOrganizationIdRequired = true;
          //requiredReportParameters.isSchoolYearRequired = true;
          requiredReportParameters.isSummarizeAtLevelRequired = true;
          requiredReportParameters.isIncludeInactiveUsersRequired = true;
          return requiredReportParameters;
        },
      };
    case "teacher-skr-summary-rounding":
      return {
        getPermissions: () => ["ReportsTeacherSKRSummaryRounding"],
        reportTitle: "Teacher SKR Summary",
        getReportTrdp: () => "TeacherSKRSummary.trdp",
        getReportParameters: (reportParameters) => ({
          ClusterId: reportParameters.clusterId,
          Individually: reportParameters.isSummarizeAtLevel === false,
          IsRounded: true,
          OrganizationId: reportParameters.organizationId,
          DistrictId: reportParameters.districtId,
          SchoolId: reportParameters.schoolId,
          SchoolYear: reportParameters.schoolYear,
          Inactive: reportParameters.includeInactiveUsers
        }),
        getReportParametersDisplay: (reportParametersDisplay) => {
          reportParametersDisplay.isRoundingTypeDisplayed = false;
          reportParametersDisplay.isOrganizationDisplayed = true;
          reportParametersDisplay.isDistrictDisplayed = true;
          reportParametersDisplay.isSchoolDisplayed = true;
          reportParametersDisplay.isSchoolYearDisplayed = true;
          reportParametersDisplay.isIncludeInactiveUsersDisplayed = true;
          return reportParametersDisplay;
        },
        getRequiredReportParameters: (requiredReportParameters) => {
          requiredReportParameters.isReportTypeRequired = true;
          requiredReportParameters.isOrganizationIdRequired = true;
          //requiredReportParameters.isSchoolYearRequired = true;
          requiredReportParameters.isSummarizeAtLevelRequired = true;
          requiredReportParameters.isIncludeInactiveUsersRequired = true;
          return requiredReportParameters;
        },
      };
    case "teacher-survey-report":
      return {
        getPermissions: () => ["ReportsTeacherSurveyReport"],
        reportTitle: "Teacher Survey Report",
        getReportTrdp: () => "TeacherSurveyReport.trdp",
        getReportParameters: (reportParameters) => ({
          ClusterId: reportParameters.clusterId,
          DistrictId: reportParameters.districtId,
          Inactive: reportParameters.includeInactiveUsers,
          OrganizationId: reportParameters.organizationId,
          SchoolId: reportParameters.schoolId,
          SchoolYear: reportParameters.schoolYear,
          Uids: reportParameters.teacherUserIds,
        }),
        getReportParametersDisplay: (reportParametersDisplay) => {
          reportParametersDisplay.isOrganizationDisplayed = true;
          reportParametersDisplay.isDistrictDisplayed = true;
          reportParametersDisplay.isSchoolDisplayed = true;
          reportParametersDisplay.isClusterDisplayed = true;
          reportParametersDisplay.isSchoolYearDisplayed = true;
          reportParametersDisplay.isIncludeInactiveUsersDisplayed = true;
          reportParametersDisplay.isTeachersDisplayed = true;
          return reportParametersDisplay;
        },
        getRequiredReportParameters: (requiredReportParameters) => {
          requiredReportParameters.isOrganizationIdRequired = true;
          //requiredReportParameters.isSchoolYearRequired = true;
          requiredReportParameters.isIncludeInactiveUsersRequired = true;
          return requiredReportParameters;
        },
      };
    case "school-authorization":
      return {
        getPermissions: () => ["ReportsSchoolAuthorization"],
        reportTitle: "School Authorization",
        getReportTrdp: () => "SchoolAuthorization.trdp",
        getReportParameters: (reportParameters) => ({
          DistrictId: reportParameters.districtId,
          OrganizationId: reportParameters.organizationId,
          SchoolId: reportParameters.schoolId,
          SchoolYear: reportParameters.schoolYear
        }),
        getReportParametersDisplay: (reportParametersDisplay) => {
          reportParametersDisplay.isOrganizationDisplayed = true;
          reportParametersDisplay.isDistrictDisplayed = true;
          reportParametersDisplay.isSchoolDisplayed = true;
          return reportParametersDisplay;
        },
        getRequiredReportParameters: (requiredReportParameters) => {
          requiredReportParameters.isOrganizationIdRequired = true;
          return requiredReportParameters;
        },
      };

    default:
      throw new Error(`Unknown report url: ${reportUrl}`);
  }
};
